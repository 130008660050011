import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import { CommonModule } from '@angular/common';
import { Select } from "@ngxs/store";
import { CompanyStateSelectors } from "../../pages/company/state/company-state.selectors";
import { Observable } from "rxjs";
import { Branch } from "../../pages/company/state/company-state.interface";
import { environment } from '@env/environment';
import { Helpers } from "../../entities/helpers";
import { takeUntil } from "rxjs/operators";
import { Subject } from "rxjs/internal/Subject";
import { ToastrService } from "ngx-toastr";
import { Router } from "@angular/router";
import { AmplitudeService } from "../../entities/amplitude.service";

@Component({
  selector: 'app-link-follow-button',
  templateUrl: './link-follow-button.html',
  styleUrls: ['./link-follow-button.scss'],
  standalone: true,
  imports: [
    CommonModule
  ],
})
export class LinkFollowButton implements OnInit, OnDestroy {
  @Select(CompanyStateSelectors.allBranches) branches$: Observable<Branch[]>;

  @Input() branch_slug: string | null = null;

  widgetLink: string | null = null;
  companyId: string | null = null;
  isLinkFollowButtonListVisible = false;

  private onDestroy$ = new Subject<void>();

  constructor(
    protected toastr: ToastrService,
    private router: Router,
    private amplitudeService: AmplitudeService,
  ) { }

  ngOnInit() {
    this.branches$.pipe(
      takeUntil(this.onDestroy$)
    ).subscribe((branches) => {
      this.companyId = branches[0]?.company_id;
      const selectedBranch = this.branch_slug || this.getSingleBranchSlug(branches);
      if (selectedBranch) {
        this.widgetLink = `${environment.widgetEndpoint}/${selectedBranch}`;
      }
    });
  }

  ngOnDestroy() {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

  getSingleBranchSlug(branches: Branch[]): string | null {
    return branches.length === 1 ? branches[0].slug : null;
  }

  trackEventTgBotClicked(bot_url: string): void {
    this.amplitudeService.trackEvent(
      'admin_telegram_bot_clicked',
      {
        company_id: this.companyId,
        location: this.router.url,
        bot_url: bot_url
      });
  }

  copyLink(url: string): void {
    Helpers.copyToClipboard(url);
    this.toastr.success(null, 'Посилання скопійовано');
  }

  copyTgLink(bot_url: string): void {
    this.copyLink(bot_url);
    this.trackEventTgBotClicked(bot_url);
  }

  copyTgBotClientLink(): void {
    this.copyTgLink('https://t.me/natodi_client_bot');
  }

  copyTgBotEmployeeLink(): void {
    this.copyTgLink('https://t.me/natodi_employee_bot');
  }

  copyWidgetLink(): void {
    if (this.widgetLink) {
      this.copyLink(this.widgetLink);
    }
  }

  navigateToLink(url: string): void {
    window.open(url, '_blank');
  }

  navigateToWidget(): void {
    if (this.widgetLink) {
      this.navigateToLink(this.widgetLink);
    }
  }

  navigateToTgBotClient(): void {
    this.navigateToLink('https://t.me/natodi_client_bot');
  }

  navigateToTgBotEmployee(): void {
    this.navigateToLink('https://t.me/natodi_employee_bot');
  }

  toggleLinkFollowButtonList(): void {
    this.isLinkFollowButtonListVisible = !this.isLinkFollowButtonListVisible;
  }

}
